import GtrSuper from "@/modules/common/components/mixins/gtr-super.mixin";
import { Component} from "vue-property-decorator";

@Component({
    name: 'GtrIdpChoose'
})
export default class GtrIdpChooseView extends GtrSuper {
    data() {
        return {
            idpData: null
        }
    }

    async mounted() {
        const idpRawData = localStorage.getItem("idp_raw")
        if (idpRawData) {
            const idpData = JSON.parse(idpRawData);
            if (idpData.length == 1) {
                this.chooseCompany(idpData[0])
                return
            }
            this.$data.idpData = idpData
        }
    }

    async chooseCompany(company) {
        const payload = {
            event_identifier: this.$route.params.event_identifier,
            data: {payload: company.data}
        }
        const result = await this.$store.dispatch('register/idpChoose', payload, { root: true })
        localStorage.setItem("idp_login", JSON.stringify(result.data))
        this.checkURLAndGo(result.data.return_url)
    }
}
